<!--
 * @Description: 客户满意度回访率统计报表
 * @Author: LiangYiNing
 * @Date: 2022-5-26 14:29:55
 * @LastEditTime: 2022-07-15 16:41:36
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="isCorpClass">
              <e6-vr-select
                v-model="searchForm.isCorpClass"
                :data="[
                  { id: 0, label: '总回访率' },
                  { id: 1, label: '客户分级' }
                ]"
                placeholder="统计类型"
                title="统计类型"
                @change="handleSearch"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="revisitTime">
              <el-date-picker
                v-model="searchForm.revisitTime"
                type="month"
                placeholder="选择月份"
                popper-class="special"
                value-format="timestamp"
                :clearable="false"
              ></el-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="tableList"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            :show-overflow-tooltip="column.fieldName !== 'attrName'"
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                v-if="column.fieldName === 'attrName'"
                class="item"
                effect="dark"
                :content="row.attrName"
                placement="right"
              >
                <div class="ellipsis">
                  {{ row.attrName }}
                </div>
              </el-tooltip>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :label="column.fieldLabel"
            v-for="(column, index) in questionColumnData"
            :key="'questionColumnData' + index"
            :align="column.align"
          >
            <el-table-column
              v-for="(answer, answersIndex) in column.answers"
              :key="'answers' + answersIndex + index"
              :prop="answer.fieldName"
              :label="answer.fieldLabel"
              :min-width="answer.width"
              :fixed="answer.fixed"
              :align="answer.align"
            >
            </el-table-column>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  customerSatisfactionReturnVisitRateReportList,
  customerSatisfactionReturnVisitRateReportExport,
  customerSatisfactionReturnVisitAnswerList
} from "@/api";
import { exportData } from "@/utils/download";
import { printError } from "@/utils/util";

// 按钮ID常量
import setting from "@/setting.js";
let { EXPORT_BUTTON_ID } = setting;

export default {
  name: "CustomerSatisfactionReturnVisitRateReport",
  data() {
    return {
      TrensferFunsApplyVisible: false,
      total: 0,

      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      EXPORT_BUTTON_ID,
      queryListAPI: customerSatisfactionReturnVisitRateReportList,
      searchForm: {
        isCorpClass: 0,
        revisitTime: new Date().getTime(), // 月份
        pageIndex: 1,
        pageSize: 20,
        sortIndex: "createdTime", // 默认创建时间排序
        sortDir: "descending" // 默认降序
      },
      questionColumnData: [
        {
          fieldName: "revisitId",
          fieldLabel: "问题一",
          align: "center",
          answers: [
            {
              fieldName: "serviceOk",
              fieldLabel: "满意",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "serviceYb",
              fieldLabel: "一般",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "serviceNo",
              fieldLabel: "不满意",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "serviceRate",
              fieldLabel: "满意率（%）",
              width: 100,
              fixed: false,
              align: "center"
            }
          ]
        },
        {
          fieldName: "revisitId",
          fieldLabel: "问题二",
          align: "center",
          answers: [
            {
              fieldName: "service2Ok",
              fieldLabel: "满意",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service2Yb",
              fieldLabel: "一般",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service2No",
              fieldLabel: "不满意",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service2Rate",
              fieldLabel: "满意率（%）",
              width: 100,
              fixed: false,
              align: "center"
            }
          ]
        },
        {
          fieldName: "revisitId",
          fieldLabel: "问题三",
          align: "center",
          answers: [
            {
              fieldName: "service3Ok",
              fieldLabel: "满意",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service3Yb",
              fieldLabel: "一般",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service3No",
              fieldLabel: "不满意",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service3Rate",
              fieldLabel: "满意率（%）",
              width: 100,
              fixed: false,
              align: "center"
            }
          ]
        },
        {
          fieldName: "revisitId",
          fieldLabel: "问题四",
          align: "center",
          answers: [
            {
              fieldName: "service4Ok",
              fieldLabel: "满意",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service4Yb",
              fieldLabel: "一般",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service4No",
              fieldLabel: "不满意",
              width: 100,
              fixed: false,
              align: "center"
            },
            {
              fieldName: "service4Rate",
              fieldLabel: "满意率（%）",
              width: 100,
              fixed: false,
              align: "center"
            }
          ]
        }
      ],
      tableData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {
    columnData() {
      let columnData = [
        {
          fieldName: "corpCount",
          fieldLabel: "总客户数",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitCount",
          fieldLabel: "回访数",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitSuccessCount",
          fieldLabel: "回访成功数",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitRate",
          fieldLabel: "回访率（%）",
          width: 120,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "revisitSuccessRate",
          fieldLabel: "回访成功率（%）",
          width: 120,
          fixed: false,
          align: "center"
        }
      ];
      if (this.searchForm.isCorpClass) {
        columnData.splice(1, 0, {
          fieldName: "corpClassName",
          fieldLabel: "客户分级",
          width: 100,
          fixed: false,
          align: "center"
        });
      }
      return columnData;
    }
  },
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.startTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    }
  },
  created() {
    // this.initData(); // 表头暂时先写死
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.questionColumnData = [];
        let res = await customerSatisfactionReturnVisitAnswerList();
        if (res.code === "OK") {
          let { data } = res;
          this.questionColumnData = data.map(item => {
            let question = {
              fieldLabel: item.question,
              align: "center",
              answers: []
            };
            // 处理二级表头 (答案表头)
            if (item.answers && item.answers.length) {
              question.answers = item.answers.map(answer => {
                return {
                  fieldName: answer.answerFieldName,
                  fieldLabel: answer.answer,
                  width: 120,
                  fixed: false,
                  align: "center"
                };
              });
            }
            return question;
          });
        }
      } catch (error) {
        printError(error);
      }
    },
    // 表格勾选
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.simPropId;
      });
    },
    // 导出
    handleExport() {
      exportData(this, customerSatisfactionReturnVisitRateReportExport);
    }
  }
};
</script>
<style lang="scss" scoped></style>
